<template>
	<div class="app-container recruitmentpostmanagement" v-loading="loading">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane :label="`未开始(${_returnNumber(0)})`" name="0"></el-tab-pane>
			<el-tab-pane :label="`进行中(${_returnNumber(1)})`" name="1"></el-tab-pane>
			<el-tab-pane :label="`已关闭(${_returnNumber(2)})`" name="2"></el-tab-pane>
		</el-tabs>
		<div class="viewBox">
			<el-row style="text-align: right; margin-bottom: 20px">
				<router-link :to="{ name: 'Addrecruitmentpostmanagement', params: { type: 'Add', id: '0' } }">
					<el-button icon="el-icon-plus" size="small" type="primary">新增岗位</el-button>
				</router-link>
			</el-row>
			<!--<el-checkbox :indeterminate="isIndeterminate" style="margin-top:10px" v-model="checkAll">全选</el-checkbox>!-->
			<el-collapse style="margin-top: 10px">
				<!--<el-checkbox-group v-model="checked">!-->
				<el-collapse-item style="position: relative" v-for="(row, i) in Data" :key="i">
					<template slot="title">
						<!--<el-checkbox :label="i">!-->
						<div class="urgentRound" v-if="row.emergencyLevel == '1'" style="margin-right: 10px">急</div>
						<!-- <el-tag
                  style="margin-right: 10px;"
                  type="danger"
                  v-if="row.emergencyLevel=='1'"
                  effect="dark"
                  size="small"
                >急</el-tag> -->
						<!--</el-checkbox>!-->
						<div class="jobDetail">
							<span>{{ row.jobName }}({{ row.number }}人)</span>{{ row.cityName }}{{ row.positionQuality
							}}<i style="margin: 0 10px">|</i>{{ row.workExperience }}<i style="margin: 0 10px">|</i>{{ row.education
							}}<i style="margin: 0 10px">|</i>{{ row.nickName }}
						</div>
						<div class="jobDetail" style="margin-left: 10px; text-align: right" v-show="activeName == '1' && row.endDay">
							<i class="el-icon-time" style="margin-right: 2px"></i
							>{{ Number(row.endDay) > 0 ? `距离结束还有${row.endDay}天` : `该招聘岗位已结束` }}
						</div>
						<el-dropdown class="collpaseItem">
							<el-button type="text" class="collapseBtn"
								><img src="../../../assets/button/操作图标@3x.png" style="vertical-align: middle" width="16px"
							/></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-for="(item, i) in commands"
									:command="item"
									:key="i"
									v-show="showWhich(item, row)"
									@click.native.stop="handleRowClick(row, item)"
									>{{ item.label }}</el-dropdown-item
								>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<!--<div class="detail">
              <div>
                <el-progress type="circle" :percentage="25" :width="80" :stroke-width="4"></el-progress>
              </div>
            </div>!-->
					<div class="stepBox">
						<el-steps
							:active="
								Number(row.endEntryCount) > 0
									? 5
									: Number(row.waitEntryCount) > 0
									? 4
									: Number(row.endInterviewCount) > 0
									? 3
									: Number(row.waitInterviewCount) > 0
									? 2
									: 1
							"
							align-center
						>
							<el-step title="接收简历" :description="row.newResumeCount"></el-step>
							<el-step title="面试邀约" :description="row.waitInterviewCount"></el-step>
							<el-step title="参加面试" :description="row.endInterviewCount"></el-step>
							<!-- <el-step title="已面试" :description="row.endInterviewCount"></el-step>!-->
							<el-step title="发送offer" :description="row.waitEntryCount"></el-step>
							<el-step title="已入职" :description="row.endEntryCount"></el-step>
						</el-steps>
					</div>
				</el-collapse-item>
				<!--</el-checkbox-group>!-->
			</el-collapse>
		</div>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200, 300, 400]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.totalNum"
		></el-pagination>
		<el-dialog title="关闭岗位" center class="closeForm" :visible.sync="dialogVisible" width="40%">
			<el-alert show-icon title="岗位关闭时转移的简历，是招聘流程中的简历、审批中、已入职的简历除外" type="warning"></el-alert>
			<el-form :model="closeForm" label-width="90px" label-position="left" style="margin: 10px 0 0">
				<el-form-item label="同时关闭">
					<el-checkbox v-model="closeForm.closealso">招聘发布</el-checkbox>
				</el-form-item>
				<el-form-item label="简历转移至">
					<el-radio-group v-model="closeForm.moveto">
						<el-radio v-for="(item, index) in classifyList" :key="index" :label="item.status">{{ item.classifyName }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirmClose">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { recruitStationList, recruitStationDelete, recruitStationClose, recruitStationNum, classifyList } from '@/api/recruitment_allocation';
// recruitStationSave,
export default {
	data() {
		return {
			loading: false,
			activeName: '0',
			isIndeterminate: true,
			dialogVisible: false,
			checkAll: false,
			checked: [],
			classifyList: [],
			searchForm: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			commands: [
				{
					label: '编辑',
					value: '1'
				},
				{
					label: '复制',
					value: '2'
				},
				{
					label: '关闭',
					value: '3'
				},
				{
					label: '删除',
					value: '4'
				},
				{
					label: '详情',
					value: '5'
				},
				{
					label: '再次发布',
					value: '6'
				}
			],
			closeForm: {
				closealso: '',
				moveto: ''
			},
			Data: [],
			numList: []
		};
	},
	filters: {
		statusFilter(itetm, status) {
			const statusMap = ['success', 'warning', 'info'];

			return statusMap[status];
		}
	},
	mounted() {
		const params = JSON.parse(sessionStorage.getItem(this.$route.name));

		if (params && params.activeName) {
			this.activeName = params.activeName;
		}
		this.getListNum();
		this.getcatelist();
		this._getClassifyList();
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(this.$route.name, JSON.stringify({ activeName: this.activeName }));
		next();
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this.getcatelist();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this.getcatelist();
		},
		getListNum() {
			recruitStationNum({}).then((res) => {
				if (res._responseStatusCode == 0) {
					delete res._responseStatusCode;
					this.numList = res;
				}
			});
		},
		_returnNumber(status) {
			if (this.numList && this.numList.length >= 1) {
				for (const key in this.numList) {
					if (this.numList[key].status == status) {
						return this.numList[key].number;
					}
				}
				return 0;
			}
			return 0;
		},
		showWhich(item, row) {
			if (this.activeName != '0' && item.value == '1') {
				return false;
			}
			if (this.activeName != '1' && item.value == '3') {
				return false;
			}
			if (this.activeName == '1' && item.value == '4') {
				return false;
			}
			if (this.activeName != '2' && item.value == '6') {
				return false;
			}
			if (this.activeName == '2' && item.value == '6') {
				if (row.number > row.endEntryCount && (row.endDay > 0 || row.endDay == null)) {
					return true;
				}
				return false;
			}
			return true;
		},
		handleRowClick(row, item) {
			switch (item.value) {
				case '1': {
					this.$router.push({
						name: 'Addrecruitmentpostmanagement',
						params: { type: 'Edit', id: row.id }
					});
					break;
				}
				case '2': {
					this.$router.push({
						name: 'Addrecruitmentpostmanagement',
						params: { type: 'Copy', id: row.id }
					});
					break;
				}
				case '3': {
					this.closeForm.id = row.id;
					this.closeForm.moveto = this.classifyList[0].status;
					this.dialogVisible = true;
					break;
				}
				case '4': {
					this.$confirm('此操作将删除该招聘岗位数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						recruitStationDelete({ id: row.id }).then((res) => {
							if (res.code === 0) {
								this.getListNum();
								this.getcatelist();
								this.$message.success('删除岗位成功');
							}
						});
					});
					break;
				}
				case '5': {
					this.$router.push({
						name: 'Addrecruitmentpostmanagement',
						params: { type: 'Info', id: row.id }
					});
					break;
				}
				case '6': {
					const params = {
						id: row.id,
						status: 1,
						resumeStatus: 0
					};

					recruitStationClose(params).then((res) => {
						if (res.code == 0) {
							this.$message.success('再次发布成功');
							this.getListNum();
							this.getcatelist();
						}
					});
					break;
				}
			}
		},
		handleClick() {
			this.getcatelist();
		},
		confirmClose() {
			const params = {
				id: this.closeForm.id,
				status: this.closeForm.closealso ? '2' : this.activeName,
				resumeStatus: this.closeForm.moveto
			};

			recruitStationClose(params).then((res) => {
				if (res.code == 0) {
					this.$message.success('关闭成功');
					this.getListNum();
					this.getcatelist();
					this.dialogVisible = false;
				}
			});
		},
		getcatelist() {
			this.loading = true;
			recruitStationList({ status: this.activeName, ...this.searchForm }).then((res) => {
				this.Data = res.list;
				this.searchForm.totalNum = res.totalNum;
				this.loading = false;
			});
		},
		search() {
			this.handleCurrentChange(1);
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getcatelist();
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getcatelist();
		},
		Delete() {
			// this.$confirm(
			//   "This action will permanently delete the column, Whether to continue?",
			//   "Prompt",
			//   {
			//     confirmButtonText: "Yes",
			//     cancelButtonText: "No",
			//     type: "warning"
			//   }
			// )
			//   .then(() => {
			//     api.deletechannel({ request: { id: row.id } }).then(res => {
			//       if (res.code === 0) {
			//         this.$message.success("successfully deleted!");
			//         this.handleCurrentChange(this.table.pageNo);
			//       }
			//     });
			//   })
			//   .catch(() => {
			//     this.$message({
			//       type: "info",
			//       message: "Undo delete operation"
			//     });
			//   });
		},
		onSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					// console.log("fail submit!!", this.form);
				} else {
					// console.log("fail submit!!", this.form);
					return false;
				}
			});
		},
		view(row) {
			this.$router.push({
				name: 'colouminfo',
				params: {
					form: row,
					status: true
				}
			});
		},
		edit(row) {
			this.$router.push({
				name: 'editcoloum',
				params: {
					form: row,
					status: false
				}
			});
		},
		_getClassifyList() {
			classifyList({}).then((res) => {
				this.classifyList = res;
			});
		}
	}
};
</script>
<style lang="scss">
.recruitmentpostmanagement {
	.el-tabs__header {
		margin-bottom: 20px;
	}
}
.viewBox {
	.router-link {
		margin-right: 10px;
	}
	.collapseBtn {
		font-size: 20px;
	}
	.collpaseItem {
		position: absolute;
		right: 0;
	}
	.el-collapse-item__arrow {
		margin: 0 21px 0 auto;
	}
	.detail {
		padding: 0 20px;
		width: 20%;
		display: inline-block;
		.el-progress--circle::after {
			content: '';
			width: 1px;
			height: 80px;
			left: 102px;
			top: 0;
			position: absolute;
			background-color: #000;
		}
	}
	.stepBox {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		.el-step__title.is-process {
			font-weight: normal;
		}
	}
}
.closeForm {
	.el-form {
		margin-top: 10px;
	}
	.el-dialog--center .el-dialog__body {
		padding-bottom: 0;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-collapse-item__content {
	padding: 30px 0;
}
.el-icon-time {
	&::before {
		color: #498df0;
	}
}
::v-deep.el-step__head.is-finish {
	color: #498df0;
	border-color: #498df0;
	::v-deep.el-step__icon.is-text {
		background-color: #498df0;
		.el-step__icon-inner {
			color: #fff;
		}
	}
}
::v-deep.el-step__head.is-process,
::v-deep.el-step__head.is-wait {
	color: #efefef;
	border-color: #efefef;
	::v-deep.el-step__icon.is-text {
		background-color: #efefef;
		.el-step__icon-inner {
			color: #c8c8c8;
		}
	}
}
::v-deep.el-step__title.is-finish,
::v-deep.el-step__description.is-finish {
	font-size: 14px;
	color: #498df0;
}
::v-deep.el-step__title.is-process,
::v-deep.el-step__description.is-process,
::v-deep.el-step__title.is-wait,
::v-deep.el-step__description.is-wait {
	font-size: 14px;
	color: #484848;
}
.urgentRound {
	background-color: #ff7371;
	font-size: 12px;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	color: #fff;
	line-height: 16px;
}
.jobDetail {
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #c8c8c8;
	span {
		color: #484848;
		margin-right: 20px;
	}
}
</style>
